@media only screen and (max-width: 767px) {
  .main {
    margin-top: 1.875rem;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    overflow-x: hidden;
  }
  .white-block {
    background-color: var(--color-light-purple-bg);
    border-radius: 1.25rem;
    color: var(--color-illustrations-black-bg);
    display: flex;
    flex-direction: column;
    margin-left: -1.125rem;
    margin-right: -1.125rem;
    margin-top: 6.25rem;
    padding-bottom: 4.9rem;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    padding-top: 5rem;
    row-gap: 6.25rem;
    --color-text-description: #555555;
    --color-text-main: var(--color-illustrations-black-bg);
    --color-text-subtitle: #3a3a3a;
  }
}


@media only screen and (min-width: 767px) and (max-width: 1439px) {
  .main {
    margin: auto;
    margin-top: 3.75rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    overflow-x: hidden;
  }
  .white-block {
    background-color: var(--color-light-purple-bg);
    border-radius: 2.5rem;
    color: var(--color-illustrations-black-bg);
    display: flex;
    flex-direction: column;
    margin-left: -2.5rem;
    margin-right: -2.5rem;
    margin-top: 10.12rem;
    padding: 6.3rem 2.5rem;
    row-gap: 7.5rem;
    --color-text-description: #555555;
    --color-text-main: var(--color-illustrations-black-bg);
    --color-text-subtitle: #3a3a3a;
  }
}
@media only screen and (min-width: 1440px) {
  .main {
    margin-top: 3.75rem;
    padding-left: 10.313rem;
    padding-right: 10.313rem;
  }
  .white-block {
    background-color: var(--color-light-purple-bg);
    border-radius: 2.5rem;
    color: var(--color-illustrations-black-bg);
    display: flex;
    flex-direction: column;
    margin-left: -10.313rem;
    margin-right: -10.313rem;
    margin-top: 10rem;
    padding: 6.25rem 10.313rem;
    row-gap: 7.5rem;
    --color-text-description: #555555;
    --color-text-main: var(--color-illustrations-black-bg);
    --color-text-subtitle: #3a3a3a;
  }
}
