@media only screen and (max-width: 767px) {
  .cta {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 1rem;
    row-gap: 0.75rem;
  }
  .button {
    --button-border-color: var(--color-button-tertiary-bg);
    --button-color: var(--button-outline-text-black);
    --button-hover-color: var(--color-brand-white);
    --button-hover-opacity: 1;
    --button-hover-background-color: var(--color-illustrations-black-bg);

    --button-font-size: 0.938rem;
    --button-line-height: 1.063rem;
    height: 2.625rem;
    width: 100%;
  }
  .headline {
    width: 100%;
  }
  .descriprion {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    row-gap: 1.875rem;
    width: 100%;
  }
  .catches {
    align-items: flex-start;
    background-color: var(--color-brand-white);
    border-radius: 0.625rem;
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: space-between;
    margin-left: -1.125rem;
    margin-right: -1.125rem;
    margin-top: 3.75rem;
    overflow: hidden;
    padding: 1.5rem;
  }
  .steps {
    height: 100%;
    width: 100%;
  }
  .step-detail {
    background-color: var(--card-styles-light-gray2-bg);
    border: 0.063rem solid var(--icon-button-default-border);
    border-radius: 0.75rem;
    display: none;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 45%;
  }
  .step-header {
    align-items: self-start;
    column-gap: 0.75rem;
    display: flex;
    margin-bottom: 0.75rem;
    width: 100%;
  }
  .step-icon {
    --icon-height: 1.5rem;
    --icon-width: 1.5rem;
    visibility: hidden;
  }
  .accordion-container {
    display: none;
    height: 100%;
    width: 43%;
  }
  .accordion-trigger {
    background-color: transparent;
    width: 100%;
  }
  .accordion-item {
    border-left: 0.125rem solid var(--color-illustrations-light-gray);
    padding: 1.125rem 1.25rem;
    padding-bottom: 0.5rem;
    width: 100%;
  }
  .accordion-item[data-active="true"] {
    background-color: var(--card-styles-purple-bg);
    border-left: 0.188rem solid var(--illustrations-purple);
  }
  .accordion-item button {
    border: none;
  }
  .progress-container {
    align-items: self-start;
    background-color: var(--color-illustrations-light-gray);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    position: relative;
    width: 50%;
  }
  .list {
    color: var(--color-text-description);
    font-size: 0.938rem;
    line-height: 1.375rem;
    padding-left: 1.6rem;
  }
  .card-image {
    object-fit: cover;
  }
  .tabs {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    width: 100%;
  }
  .tabs__list {
    text-align: center;
    cursor: default;
    display: flex;
  }
  .tab__item[data-active="true"] .step-icon {
    visibility: visible;
  }
  .tab__item {
    background: transparent;
    border: none;
    border-bottom: 0.188rem solid var(--color-illustrations-light-gray);
    font-size: 1.125rem;
    line-height: 1.375rem;
    outline: none;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    column-gap: 0.25rem;
    min-width: fit-content;
    margin-top: -0.125rem;
    width: 33.3%;
  }
  .tab__item[data-active="true"] {
    border-bottom: 0.188rem solid var(--illustrations-purple);
  }
  .tab__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 29rem;
  }
  .tab__content[hidden] {
    display: none;
  }
  .tab__content-desscription {
    background-color: var(--card-styles-purple-bg);
    padding: 1.125rem 1.25rem;
  }
  .tab__content-card {
    background-color: var(--card-styles-light-gray2-bg);
    border: 0.063rem solid var(--icon-button-default-border);
    border-radius: 0.75rem;
    height: 18.75rem;
    /* margin-top: 2.5rem; */
    margin-top: auto;
    position: relative;
    overflow: hidden;
  }
  .tab__content-card-content {
    position: relative;
    max-width: 31.063rem;
    margin: 0 auto;
  }
  .card-image-availability-monitoring {
    position: absolute;
    top: 2.5rem !important;
    left: calc(-25rem + 60vw) !important;
    width: 17.438rem !important;
    height: 17.75rem !important;
  }
  .card-image-availability-phone {
    position: absolute;
    top: -2.5rem !important;
    left: calc(-20rem + 60vw) !important;
    width: 37.375rem !important;
    height: 31.875rem !important;
  }
  .card-image-analytics {
    position: absolute;
    object-fit: fill;
    top: 5rem !important;
    border-radius: 0.5rem;
    left: 1rem !important;
    width: 25.875rem !important;
    height: 13.813rem !important;
  }
  .card-image-task-1 {
    position: absolute;
    object-fit: fill;
    top: 16.25rem !important;
    border-radius: 0.5rem;
    left: 2.25rem !important;
    width: 15.938rem !important;
    height: 2.5rem !important;
  }
  .card-image-task-2 {
    position: absolute;
    object-fit: fill;
    top: 0.5rem !important;
    border-radius: 0.5rem;
    left: 12.5rem !important;
    width: 18.5rem !important;
    height: 14.5rem !important;
    z-index: 1;
  }
  .card-image-task-3 {
    position: absolute;
    object-fit: fill;
    top: 2.5rem !important;
    border-radius: 0.5rem;
    left: 1.375rem !important;
    width: 17.575rem !important;
    height: 14.25rem !important;
  }
  
  .card-description {
    position: absolute;
    top: 2rem;
    left: 1rem;
    width: 60%;
    font-weight: 500;
    --font-size: 0.875rem;
    --line-height: 1.05rem;
  }
  .card-description > span {
    color: var(--color-card-caption);
    font-size: 0.813rem;
    line-height: 0.988rem;
  }
  .card-description-tasks {
    top: 1.25rem;
    left: 2.5rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1439px) {
  .cta {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0.5rem;
    row-gap: 1.5rem;
  }
  .button {
    --button-border-color: var(--color-button-tertiary-bg);
    --button-color: var(--button-outline-text-black);
    --button-hover-color: var(--color-brand-white);
    --button-hover-opacity: 1;
    --button-hover-background-color: var(--color-illustrations-black-bg);
    --button-icon-height: 0.8rem;
    --button-icon-width: 0.8rem;
    height: 3.375rem;
    width: 17.625rem;
  }
  .headline {
    width: 75%;
  }
  .descriprion {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    row-gap: 1.875rem;
    width: 80%;
  }
  .catches {
    align-items: flex-start;
    background-color: var(--color-brand-white);
    border-radius: 1.25rem;
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: space-between;
    margin-top: 3.75rem;
    padding: 2.5rem;
  }
  .steps {
    height: 100%;
    width: 100%;
  }
  .step-detail {
    background-color: var(--card-styles-light-gray2-bg);
    border: 0.063rem solid var(--icon-button-default-border);
    border-radius: 0.75rem;
    display: none;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 45%;
  }
  .step-header {
    align-items: self-start;
    column-gap: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
  .step-icon {
    display: none;
    --icon-height: 2rem;
    --icon-width: 2rem;
  }
  .accordion-container {
    display: none;
  }
  .accordion-trigger {
    background-color: transparent;
    width: 100%;
  }
  .accordion-item {
    border-left: 0.125rem solid var(--color-illustrations-light-gray);
    padding: 1.125rem 1.25rem;
    padding-bottom: 0.5rem;
    width: 100%;
  }
  .accordion-item[data-active="true"] {
    background-color: var(--card-styles-purple-bg);
    border-left: 0.188rem solid var(--illustrations-purple);
  }
  .accordion-item button {
    border: none;
  }
  .progress-container {
    align-items: self-start;
    background-color: var(--color-illustrations-light-gray);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    position: relative;
    width: 50%;
  }
  .list {
    color: var(--color-text-description);
    font-size: 0.938rem;
    line-height: 1.375rem;
    padding-left: 1.6rem;
  }
  .card-image {
    object-fit: fill;
  }
  .tabs {
    display: flex;
    flex-direction: column;
    row-gap: 1.25rem;
    width: 100%;
  }
  .tabs__list {
    text-align: center;
    cursor: default;
    display: flex;
  }
  .tab__item {
    background: transparent;
    border: none;
    border-bottom: 0.188rem solid var(--color-illustrations-light-gray);
    font-size: 1.25rem;
    line-height: 1.625rem;
    outline: none;
    padding: 1rem 0.125rem;
    padding-bottom: 0.75rem;
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 0.3rem;
    justify-content: center;
  }
  .tab__item[data-active="true"] {
    border-bottom: 0.188rem solid var(--illustrations-purple);
  }
  .tab__item[data-active="true"] .step-icon {
    display: block;
  }
  .tab__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 29rem;
  }
  .tab__content[hidden] {
    display: none;
  }
  .tab__content-desscription {
    background-color: var(--card-styles-purple-bg);
    padding: 1.125rem 1.25rem;
  }
  .tab__content-card {
    background-color: var(--card-styles-light-gray2-bg);
    border: 0.063rem solid var(--icon-button-default-border);
    border-radius: 0.75rem;
    height: 18.75rem;
    margin-top: auto;
    /* margin-top: 2.5rem; */
    position: relative;
    overflow: hidden;
  }

  .tab__content-card-content {
    position: relative;
    max-width: 31.063rem;
    margin: 0 auto;
  }
  .card-image-availability-monitoring {
    position: absolute;
    top: 2.5rem !important;
    left: 2rem !important;
    width: 17.438rem !important;
    height: 17.75rem !important;
  }
  .card-image-availability-phone {
    position: absolute;
    top: -2.5rem !important;
    left: 6rem !important;
    width: 37.375rem !important;
    height: 31.875rem !important;
  }
  .card-image-analytics {
    position: absolute;
    object-fit: fill;
    top: 5rem !important;
    border-radius: 0.5rem;
    left: 1rem !important;
    width: 25.875rem !important;
    height: 13.813rem !important;
  }
  .card-image-task-1 {
    position: absolute;
    object-fit: fill;
    top: 16.25rem !important;
    border-radius: 0.5rem;
    left: 2.25rem !important;
    width: 15.938rem !important;
    height: 2.5rem !important;
  }
  .card-image-task-2 {
    position: absolute;
    object-fit: fill;
    top: 0.5rem !important;
    border-radius: 0.5rem;
    left: 12.5rem !important;
    width: 18.5rem !important;
    height: 14.5rem !important;
    z-index: 1;
  }
  .card-image-task-3 {
    position: absolute;
    object-fit: fill;
    top: 2.5rem !important;
    border-radius: 0.5rem;
    left: 1.375rem !important;
    width: 17.575rem !important;
    height: 14.25rem !important;
  }

  .card-description {
    position: absolute;
    top: 1.5rem;
    left: 1rem;
    width: 60%;
    font-weight: 500;
    --font-size: 0.875rem;
    --line-height: 1.05rem;
  }
  .card-description > span {
    color: var(--color-card-caption);
    font-size: 0.813rem;
    line-height: 0.988rem;
  }
  .card-description-tasks {
    top: 1.25rem;
    left: 2.5rem;
  }
}
@media only screen and (min-width: 1440px) {
  .cta {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
  }
  .button {
    --button-border-color: var(--color-button-tertiary-bg);
    --button-color: var(--button-outline-text-black);
    --button-hover-color: var(--color-brand-white);
    --button-hover-opacity: 1;
    --button-hover-background-color: var(--color-illustrations-black-bg);
    --button-icon-height: 0.8rem;
    --button-icon-width: 0.8rem;

    height: 3.375rem;
    width: 17.625rem;
  }
  .headline {
    width: 43%;
  }
  .descriprion {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    row-gap: 1.875rem;
    margin-top: 0.75rem;
    margin-right: -1rem;
    width: 50%;
  }
  .catches {
    align-items: flex-start;
    background-color: var(--color-brand-white);
    border-radius: 1.25rem;
    display: flex;
    height: 24.5rem;
    justify-content: space-between;
    margin-top: 3.75rem;
    padding: 2.5rem;
  }
  .steps {
    height: 100%;
    width: 100%;
  }
  .step-detail {
    background-color: var(--card-styles-light-gray2-bg);
    border: 0.063rem solid var(--icon-button-default-border);
    border-radius: 0.75rem;
    margin-top: 0.5rem;
    height: 96%;
    overflow: hidden;
    position: relative;
    width: 48.5%;
  }
  .step-detail-content {
    position: relative;
    width: 100%;
    margin: 0 auto;
    max-width: 31.063rem;
  }
  .step-header {
    align-items: self-start;
    column-gap: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    font-size: 1.25rem;
    line-height: 1.563rem;
  }
  .step-icon {
    --icon-height: 2rem;
    --icon-width: 2rem;
  }
  .accordion-container {
    height: 100%;
    max-height: 100%;
    width: 43%;
  }

  .accordion-trigger {
    background-color: transparent;
    width: 100%;
  }
  .accordion-item {
    border-left: 0.125rem solid var(--color-illustrations-light-gray);
    padding: 1.125rem 1.125rem;
    width: 100%;
  }

  .accordion-item[data-state="open"] {
    padding-top: 2rem;
  }
  .accordion-item[data-state="open"]:last-child {
    padding-top: 1.125rem;
    overflow: hidden;
  }
  .accordion-item[data-active="true"] {
    background-color: var(--card-styles-purple-bg);
    border-left: 0.188rem solid var(--illustrations-purple);
  }
  .accordion-item button {
    border: none;
  }
  @keyframes accordian-down {
    from {
      height: 0;
      padding-top: 0rem;
    }
    to {
      height: calc(var(--radix-accordion-content-height) + 0.5rem);
      padding-top: 0.5rem;
    }
  }

  @keyframes accordian-up {
    from {
      height: calc(var(--radix-accordion-content-height) + 0.5rem);
      padding-top: 0.5rem;
    }
    to {
      height: 0;
      padding-top: 0rem;
    }
  }
  .accordion-content {
    padding-top: 0.5rem;
    overflow: hidden;
  }
  .accordion-content[data-state="open"] {
    animation: accordian-down 0.3s forwards;
  }
  .accordion-content[data-state="closed"] {
    animation: accordian-up 0.3s forwards;
  }
  .progress-container {
    align-items: self-start;
    background-color: var(--color-illustrations-light-gray);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    position: relative;
    width: 50%;
  }
  .list {
    color: var(--color-text-description);
    font-size: 0.938rem;
    line-height: 1.375rem;
    padding-left: 1.6rem;
  }
  .card-image {
    object-fit: fill;
  }
  .tabs {
    display: none;
  }

  .card-image-availability-monitoring {
    position: absolute;
    top: 2.5rem !important;
    left: 2rem !important;
    width: 17.438rem !important;
    height: 17.75rem !important;
  }
  .card-image-availability-phone {
    position: absolute;
    top: -2.5rem !important;
    left: 6rem !important;
    width: 37.375rem !important;
    height: 31.875rem !important;
  }
  .card-image-analytics {
    position: absolute;
    object-fit: fill;
    top: 4rem !important;
    border-radius: 0.5rem;
    left: 2rem !important;
    width: 25.875rem !important;
    height: 13.813rem !important;
  }
  .card-image-task-1 {
    position: absolute;
    object-fit: fill;
    top: 16.25rem !important;
    border-radius: 0.5rem;
    left: 2.25rem !important;
    width: 15.938rem !important;
    height: 2.5rem !important;
  }
  .card-image-task-2 {
    position: absolute;
    object-fit: fill;
    top: 0.5rem !important;
    border-radius: 0.5rem;
    left: 12.5rem !important;
    width: 18.5rem !important;
    height: 14.5rem !important;
    z-index: 1;
  }
  .card-image-task-3 {
    position: absolute;
    object-fit: fill;
    top: 2.5rem !important;
    border-radius: 0.5rem;
    left: 1.375rem !important;
    width: 17.575rem !important;
    height: 14.25rem !important;
  }
  .card-description {
    position: absolute;
    top: 1.5rem;
    left: 2rem;
    width: 90%;
    font-weight: 500;
    --font-size: 1rem;
    --line-height: 1.2rem;
  }
  .card-description > span {
    color: var(--color-card-caption);
    font-size: 0.813rem;
    line-height: 0.988rem;
  }
  .card-description-tasks {
    top: 1.25rem;
    left: 2.5rem;
  }
}
